@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(../src/components/css/font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}
input:focus {
  outline: none;
}
select:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

.App {
  display: flex;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  /* justify-content: flex-start; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

/* pagination */
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 30px;
  width: 100%;
  height: 35px;;
  background-color: transparent;
}
.pagination button:hover {
  cursor: pointer;
}
.pagination > #prev {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  border: none;
  background-color: transparent;
  width: 30px;
}
.pagination > #prev > img {
  width: 28px;
}
.pagination > #next {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  border: none;
  background-color: transparent;
  width: 30px;
}
.pagination > #next > img {
  width: 28px;
}
.pagination > .pagination_num {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: transparent;
}
.pagination > .pagination_num > button {
  width: 30px;
  height: 28px;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  border: none;
  background-color: transparent;
  color: #0d3b1e;
}
.pagination > .pagination_num > button:hover {
  transform: scale(1.3);
}
.pagination > .plus {
  position: absolute;
  bottom: 0px;
  left: 20px;
  padding: 5px 12px 5px 10px;
  border-radius: 5px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.pagination > .plus:hover {
  cursor: pointer;
  background-color: transparent;
  transform: scale(1.05);
}
.pagination > .plus > .file_plus {
  width: 25px;
  margin-right: 4px;
}
.pagination > .plus > span {
  font-size: 15px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  color: #568566;
}

.pagination > .minus {
  position: absolute;
  bottom: 0px;
  left: 120px;
  padding: 5px 12px 5px 10px;
  border-radius: 5px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.pagination > .minus:hover {
  cursor: pointer;
  background-color: transparent;
  transform: scale(1.05);
}
.pagination > .minus > .file_minus {
  width: 25px;
  margin-right: 4px;
}
.pagination > .minus > span {
  font-size: 15px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  color: #8e8e93;
}

.pagination > .down {
  position: absolute;
  bottom: 0px;
  left: 20px;
  padding: 5px 12px 5px 10px;
  border-radius: 5px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.pagination > .down:hover {
  cursor: pointer;
  background-color: transparent;
  transform: scale(1.05);
}
.pagination > .down > .down {
  width: 25px;
  margin-right: 4px;
}
.pagination > .down > span {
  font-size: 15px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  color: #568566;
}

/* .Service_log_1 */
.Service_log_1 .pagination {
  position: absolute;
  bottom: 20px;
}
/* Notice_1 */
.Notice_1 .pagination {
  position: absolute;
  bottom: 20px;
}
/* Faq_1 */
.Faq_1 .pagination {
  position: absolute;
  bottom: 20px;
}
/* Diagnosis */
.Diagnosis .pagination {
  position: absolute;
  bottom: 20px;
}