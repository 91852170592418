@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.Diagnosis_2:hover {
  cursor: pointer;
  background-color: #d8d8d83a;
  border: solid 1px #d8d8d8a8;
}
.Diagnosis_2 {
  width: 100%;
  height: 60px;
  font-family: "Pretendard-Regular";
  font-size: 17px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  color: #333;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 30px;
}
.Diagnosis_2 span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.Diagnosis_2 span:first-child {
  width: 3%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(2) {
  /* className='idxDiv' */
}
.Diagnosis_2 span:nth-child(3) {
  width: 8%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow: hidden;
}
.Diagnosis_2 span:nth-child(4) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(5) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(6) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(7) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(8) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(9) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(10) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(11) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(12) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(13) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(14) {
  width: 7%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Diagnosis_2 span:nth-child(15) {
  width: 12%;
  height: 100%;
  background-color: transparent;
  margin-bottom: 2px;
  overflow: hidden;
}

/* scrollbar */
.Diagnosis_2 span::-webkit-scrollbar {
  height: 5px;
}
.Diagnosis_2 span::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Diagnosis_2 span::-webkit-scrollbar-track {
  background: transparent;
}
.Diagnosis_2 span::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Diagnosis_2 span::-webkit-scrollbar-button:end {
  background-color: transparent;
}
