@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.Service_log_1 {
  width: 100%;
  height: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.Service_log_1 > .boxTitle {
  width: 100%;
  height: 35px;;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  background-color: #368552;
  border-radius: 5px;
  padding: 0 30px;
}
.Service_log_1 > .boxTitle span:first-child {
  width: 12%;
  background-color: transparent;
  text-align: left;
}
.Service_log_1 > .boxTitle span:nth-child(2) {
  width: 28%;
  background-color: transparent;
  text-align: left;
}
.Service_log_1 > .boxTitle span:nth-child(3) {
  width: 48%;
  background-color: transparent;
  text-align: left;
  height: 10px;
}
.Service_log_1 > .boxTitle span:nth-child(4) {
  width: 12%;
  background-color: transparent;
  text-align: left;
}

.Service_log_1 > .logList_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
}

/* .Service_log_1 > .logList_box > scroll */
.Service_log_1 > .logList_box::-webkit-scrollbar {
  width: 10px;
}
.Service_log_1 > .logList_box::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Service_log_1 > .logList_box::-webkit-scrollbar-track {
  background: transparent;
}
.Service_log_1 > .logList_box::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Service_log_1 > .logList_box::-webkit-scrollbar-button:end {
  background-color: transparent;
}

@media screen and (max-width: 821px) and (min-width: 250px) {
  .Service_log_1 {
    width: 100%;
    height: 100%;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }
  .Service_log_1 > .boxTitle {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "Pretendard-Regular";
    font-size: 0.8em;
    font-weight: bold;
    border-bottom: solid 1px #9d9d9d39;
    color: rgb(255, 255, 255);
    background-color: #368552;
  }
  .Service_log_1 > .boxTitle span {
    width: 31%;
    background-color: transparent;
    text-align: center;
  }
  .Service_log_1 > .boxTitle span:nth-child(2) {
    margin: 0px 2%;
  }
  .Service_log_1 > .logList_box {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 20px;
    background-color: rgb(255, 255, 255);
  }

  /* .Service_log_1 > .logList_box > scroll */
  .Service_log_1 > .logList_box::-webkit-scrollbar {
    width: 5px;
  }
  .Service_log_1 > .logList_box::-webkit-scrollbar-thumb {
    height: 20%;
    background: #d3d3d3d0;
    border-radius: 5px;
  }
  .Service_log_1 > .logList_box::-webkit-scrollbar-track {
    background: transparent;
  }
  .Service_log_1 > .logList_box::-webkit-scrollbar-button:start {
    background-color: transparent;
  }
  .Service_log_1 > .logList_box::-webkit-scrollbar-button:end {
    background-color: transparent;
  }
}
