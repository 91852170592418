@charset "utf-8";

@font-face {
  font-family: "SourceSansPro-Light";
  src: url(./components/css/font/SourceSansPro-Light.ttf);
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url(./components/css/font/SourceSansPro-SemiBold.ttf);
  font-weight: 500;
  font-style: normal;
}

.Login {
  width: 350px;
  height: 400px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius:10px;
  border: solid 2px #37945821;
}
.Login > img {
  margin-bottom: 60px;
}

/* .Login_form */
.Login > .Login_form {
  width: 270px;
  background-color: rgb(255, 255, 255);
}
.Login_form #email {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.144);
  font-size: 15px;
  padding: 0 10px;
  margin-bottom: 20px;
  font-family: "SourceSansPro-Light";
  color: rgb(0, 0, 0);
}
.Login_form #password {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.144);
  font-size: 15px;
  padding: 0 10px;
  margin-bottom: 60px;
  font-family: "SourceSansPro-Light";
  color: rgb(0, 0, 0);
}
.Login_form #submit {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.144);
  font-size: 15px;
  text-align: center;
  background-color: #379458;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "SourceSansPro-SemiBold";
  padding-bottom: 2px;
}
.Login_form #submit:hover {
  cursor: pointer;
  background-color: #368552;
}
