@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.Notice {
  width: 100%;
  height: 100%;
  padding-right: 2px;
  background-color: inherit;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
}
.Notice > .title {
  width: 100%;
  height: 5%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  margin-bottom: 15px;
  color: #414141;
  letter-spacing: 1px;
  font-size: 22px;
  position: relative;
}
.Notice > .title > span {
  margin-left: 5px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
/* serchBar */
.Notice > .title > .serchBar {
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  position: absolute;
  right: 8px;
  display: flex;
  flex-direction: row;
}
.Notice > .title > .serchBar > #selectSearch {
  font-size: 16px;
  font-family: "Pretendard-Regular";
  width: 100px;
  height: 30px;
  margin-right: -10px;
  border: none;
  border-bottom: solid 1px rgba(188, 188, 188, 0.29);
  color: rgb(125, 125, 125);
  background-color: #f9f9f9;
  border-radius: 5px;
}
.Notice > .title > .serchBar > .inputSearch {
  font-size: 16px;
  font-family: "Pretendard-Regular";
  margin-right: 10px;
  width: 250px;
  height: 30px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: none;
  border-bottom: solid 1px rgba(188, 188, 188, 0.29);
  background-color: #f9f9f9;
  border-radius: 5px;
}
.Notice > .title > .serchBar > .querySearch {
  height: 25px;
  margin-right: 10px;
}
.Notice > .title > .serchBar > .querySearch:hover {
  cursor: pointer;
}

/* Notice_board */
.Notice > .Notice_board {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  background-color: #f9f9f9;
}

/* Notice_detail */
.Notice > .title > .double_left {
  width: 27px;
  margin-right: 10px;
}
.Notice > .title > .double_left:hover {
  cursor: pointer;
}
.Notice > .title > .title_date {
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: #414141;
}

.Notice > .Notice_detail {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
}
.Notice > .Notice_detail > div:first-child {
  width: 100%;
  padding: 20px 20px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #414141;
  background-color: #368552;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.Notice > .Notice_detail > div:first-child > .btnBox {
  position: absolute;
  right: 20px;
}
.Notice > .Notice_detail > div:first-child > .btnBox > img:first-child {
  margin-right: 10px;
}
.Notice > .Notice_detail > div:first-child > .btnBox img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.Notice > .Notice_detail > div:nth-child(2) {
  width: 100%;
  height: 100%;
  padding: 0px 15px 0px 10px;
  overflow-y: scroll;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  flex-direction: column;
  background-color: inherit;
}

/* edit_title */
.Notice > .edit_title {
  width: 100%;
  height: 5%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  margin-bottom: 15px;
  color: #414141;
  letter-spacing: 1px;
  font-size: 22px;
  position: relative;
  display: none;
}
.Notice > .edit_title > span {
  margin-left: 5px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.Notice > .edit_title > .double_left {
  width: 27px;
  margin-right: 10px;
}
.Notice > .edit_title > .double_left:hover {
  cursor: pointer;
}
.Notice > .edit_title .edit_title {
  width: 400px;
  border: none;
  border-bottom: solid 1px #cccccc;
  background-color: transparent;
  color: #414141;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  padding: 0 5px;
}
.Notice > .edit_title > .title_date {
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: #368552;
}

/* Notice_edit */
.Notice > .Notice_edit {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  background-color: transparent;
  display: none;
}
.Notice > .Notice_edit > div:first-child {
  width: 100%;
  padding: 20px 20px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #414141;
  background-color: #368552;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.Notice > .Notice_edit > div:first-child > .edit_writer {
  border: none;
  background-color: rgba(240, 240, 240, 0.589);
  height: 25px;
  margin-left: 5px;
  border-radius: 5px;
  padding: 1px 5px 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #414141;
}
.Notice > .Notice_edit > div:first-child > .btnBox {
  position: absolute;
  right: 20px;
}
.Notice > .Notice_edit > div:first-child > .btnBox > img:first-child {
  margin-right: 10px;
}
.Notice > .Notice_edit > div:first-child > .btnBox img:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.Notice > .Notice_edit > div:nth-child(2) {
  width: 100%;
  height: 100%;
  padding: 0px 15px 0px 10px;
  overflow-y: scroll;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  flex-direction: column;
  background-color: inherit;
}

/* scrollbar */
.Notice > .Notice_detail > div:nth-child(2)::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}
.Notice > .Notice_detail > div:nth-child(2)::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Notice > .Notice_detail > div:nth-child(2)::-webkit-scrollbar-track {
  background: transparent;
}
.Notice > .Notice_detail > div:nth-child(2)::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Notice > .Notice_detail > div:nth-child(2)::-webkit-scrollbar-button:end {
  background-color: transparent;
}
.Notice > .Notice_edit > div:nth-child(2)::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}
.Notice > .Notice_edit > div:nth-child(2)::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Notice > .Notice_edit > div:nth-child(2)::-webkit-scrollbar-track {
  background: transparent;
}
.Notice > .Notice_edit > div:nth-child(2)::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Notice > .Notice_edit > div:nth-child(2)::-webkit-scrollbar-button:end {
  background-color: transparent;
}

/* ck-editor */
.ck.ck-editor {
  position: relative;
  display: flex;
  flex-direction: column;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f9f9f9;
  border: none;
  position: fixed;
}
.ck-editor__editable {
  background-color: transparent !important;
  margin-top: 50px;
}
