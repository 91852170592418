@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./components/css/font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.Navigation {
  max-width: 150px;
  width: auto;
  min-width: 150px;
  height: 100%;
  background-color: #f9f9f9;
  padding-left: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.Navigation > .profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 10px;
  border-radius: 2px;
  margin-bottom: 20px;
}
.Navigation > .profile > span:first-child {
  margin-bottom: 10px;
  color: #368552;
  font-weight: bold;
}
.Navigation > .profile > span:nth-child(2) {
  color: #919191;
  font-weight: 600;
  font-size: 14px;
}
.Navigation button {
  width: 100%;
  height: 36px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: 5px;
  border: none;
  margin-bottom: 15px;
}

/* LogoutBtn */
.Navigation .LogoutBtn {
  position: absolute;
  bottom: 0;
  width: 140px;
  height: 30px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: 5px;
  border: none;
  background-color: #f9f9f9;
  color: rgb(70, 70, 70);
  font-weight: 400;
}
.Navigation .LogoutBtn:hover {
  background-color: #acacac5d;
  font-weight: bold;
  cursor: pointer;
}
.Navigation .LogoutBtn:active {
  background-color: #acacac5d;
  font-weight: bold;
  cursor: pointer;
}
.Navigation .LogoutBtn img {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}
.Navigation .LogoutBtn span {
  font-size: 14px;
  font-family: "Pretendard-Regular";
  font-style: normal;
}

/* menuBtn */
.Navigation .menuBtn {
  width: 100%;
  height: 36px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: 5px;
  border: none;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  color: rgb(70, 70, 70);
  font-weight: 400;
}
.Navigation .menuBtn:hover {
  background-color: #d8d8d85d;
  cursor: pointer;
}
.Navigation .menuBtn:active {
  background-color: #d8d8d85d;
  cursor: pointer;
}
.Navigation .menuBtn img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.Navigation .menuBtn span {
  font-size: 16px;
  font-family: "Pretendard-Regular";
  font-style: normal;
}