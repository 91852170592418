@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.Notice_1 {
  width: 100%;
  height: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.Notice_1 > .boxTitle {
  width: 100%;
  height: 35px;;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  background-color: #368552;
  border-radius: 5px;
  padding: 0 30px;
}
.Notice_1 > .boxTitle span:first-child {
  width: 4%;
  background-color: transparent;
  text-align: left;
}
.Notice_1 > .boxTitle span:nth-child(2) {
  /* className='idxDiv' */
}
.Notice_1 > .boxTitle span:nth-child(3) {
  width: 60%;
  background-color: transparent;
  text-align: left;
}
.Notice_1 > .boxTitle span:nth-child(4) {
  width: 22%;
  background-color: transparent;
  text-align: left;
}
.Notice_1 > .boxTitle span:nth-child(5) {
  width: 14%;
  background-color: transparent;
  text-align: left;
}
.Notice_1 > .noticeList_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
}
