@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.Notice_2:hover {
  cursor: pointer;
  font-weight: bold;
}
.Notice_2 {
  width: 100%;
  height: 55px;
  font-family: "Pretendard-Regular";
  font-size: 17px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  color: #333;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 30px;
}
.Notice_2 span:first-child {
  width: 4%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Notice_2 span:nth-child(2) {
  /* className='idxDiv' */
}
.Notice_2 span:nth-child(3) {
  width: 60%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Notice_2 span:nth-child(4) {
  width: 22%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Notice_2 span:nth-child(5) {
  width: 14%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}

/* scrollbar */
.Notice_2 span::-webkit-scrollbar {
  height: 5px;
}
.Notice_2 span::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Notice_2 span::-webkit-scrollbar-track {
  background: transparent;
}
.Notice_2 span::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Notice_2 span::-webkit-scrollbar-button:end {
  background-color: transparent;
}
