@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url(./font/SourceSansPro-SemiBold.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
}

.Dashboard {
  width: 100%;
  height: 100%;
  padding-right: 2px;
  background-color: inherit;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* title */
.Dashboard > .title {
  width: 100%;
  height: 5%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  margin-bottom: 15px;
  color: #414141;
  letter-spacing: 1px;
  font-size: 22px;
  position: relative;
}
.Dashboard > .title > span {
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  position: absolute;
  right: 8px;
}

/* list_wrap */
.Dashboard > .list_wrap {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
}