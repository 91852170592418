@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.nice-dates {
  font-family: "Pretendard-Regular";
  margin-right: 25px;
}
.nice-dates input {
  font-family: "Pretendard-Regular";
  font-size: 1.1em;
  color: rgb(67, 67, 67);
  width: 250px;
  height: 30px;
  text-align: center;
  border: solid 1px rgba(153, 153, 153, 0.235);
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px rgba(151, 151, 151, 0.157);
  display: none;
  margin-left: 25px;
  margin-right: -12px;
}
.nice-dates .date-range {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nice-dates .date-range input {
  display: flex;
  justify-content: center;
  padding: 0px 25px;
  width: 225px;
  height: 45px;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Pretendard-Regular";
  border: solid 1px #c7c7c7;
  background-color: rgb(255, 255, 255);
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}
.eee {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
  margin: 0px 10px 0px;
}
.nice-dates-navigation {
  align-items: center;
  color: rgb(51, 51, 51);
  display: flex;
  justify-content: space-between;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}
.nice-dates-navigation_current {
  flex-grow: 1;
  font-size: 17px;
}
.nice-dates-navigation_previous,
.nice-dates-navigation_next {
  cursor: pointer;
  height: 0;
  padding-bottom: calc(100% / 7);
  position: relative;
  width: calc(100% / 7);
}
.nice-dates-navigation_previous:before,
.nice-dates-navigation_next:before {
  border-right: 2px solid #999;
  border-top: 2px solid #999;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 14px;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  transition: 200ms border-color;
  width: 14px;
}
.nice-dates-navigation_previous:hover:before,
.nice-dates-navigation_next:hover:before {
  border-color: #333;
}
.nice-dates-navigation_previous.-disabled,
.nice-dates-navigation_next.-disabled {
  cursor: default;
  pointer-events: none;
}
.nice-dates-navigation_previous.-disabled:before,
.nice-dates-navigation_next.-disabled:before {
  border-color: #ddd;
}
.nice-dates-navigation_previous:before {
  transform: rotate(-135deg);
}
.nice-dates-week-header {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 8px 0;
}
.nice-dates-week-header_day {
  color: #999;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  width: calc(100% / 7);
}
.nice-dates-grid {
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.nice-dates-grid_container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  position: absolute;
  right: 0;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: none;
  will-change: transform;
}
.nice-dates-grid_container.-moving .nice-dates-day_date {
  transition: 300ms color;
}
.nice-dates-grid_container.-origin-bottom {
  top: auto;
  bottom: 0;
}
.nice-dates-grid_container.-origin-top {
  top: 0;
  bottom: auto;
}
.nice-dates-grid_container.-transition {
  transition-property: transform;
}
.nice-dates-day {
  border-top: 1px solid transparent;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  text-align: center;
  width: calc(100% / 7);
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nice-dates-day_date {
  transition: 150ms color;
  will-change: color;
  z-index: 3;
}
.nice-dates-day_month {
  color: #999;
  font-size: 12px;
  left: 0;
  line-height: 1;
  position: absolute;
  right: 0;
  text-transform: capitalize;
  top: 10%;
  z-index: 3;
}
.nice-dates-day:before,
.nice-dates-day:after {
  border-radius: 999px;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.nice-dates-day:before {
  background-color: #379458;
  z-index: 1;
  margin: 2%;
  width: 96%;
  height: 96%;
}
.nice-dates-day:after {
  border: 2px solid #379458;
  transform: scale(0.95);
  transition-duration: 150ms;
  transition-property: transform, opacity;
  z-index: 2;
}
.nice-dates-day:not(.-disabled):hover:after {
  opacity: 1;
  transform: scale(1);
}
@media (hover: none) {
  .nice-dates-day:after {
    content: none;
  }
  .nice-dates-day.-selected * {
    color: #fff;
  }
}
.nice-dates-day.-selected:before {
  opacity: 1;
}
.nice-dates-day.-selected:hover:after {
  background-color: #fff;
}
.nice-dates-day.-selected:not(:hover) *,
.nice-dates-day.-selected.-disabled * {
  color: #fff;
}
.nice-dates-day.-selected.-selected-start:before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0;
}
.nice-dates-day.-selected.-selected-end:before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: 0;
}
.nice-dates-day.-selected.-selected-middle:before {
  border-radius: 0;
  left: 0;
  right: 0;
}
.nice-dates-day.-selected.-selected-middle:nth-child(7n + 1):before {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n):before {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.nice-dates-day.-today {
  font-weight: 600;
}
.nice-dates-day.-wide:before,
.nice-dates-day.-wide:after {
  left: 12.5%;
  right: 12.5%;
}
.nice-dates-day.-outside {
  color: #999;
}
.nice-dates-day.-disabled {
  cursor: default;
  pointer-events: none;
  color: #ddd;
}
.nice-dates-popover {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  margin: 8px 0;
  max-width: 350px;
  position: absolute;
  transform-origin: top;
  transform: perspective(1000px);
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
  width: 350px;
  height: 400px;
  will-change: transform, opacity;
  z-index: 9;
  right: 90px;
}
.nice-dates-popover:not(.-open) {
  opacity: 0;
  pointer-events: none;
  transform: perspective(1000px) rotateX(-15deg);
}

