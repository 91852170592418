@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.Tos {
  width: 100%;
  height: 100%;
  padding-right: 2px;
  background-color: inherit;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
}
.Tos > .title {
  width: 100%;
  height: 8%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  margin-bottom: 15px;
  color: #414141;
  letter-spacing: 1px;
  font-size: 22px;
  position: relative;
}
.Tos > .title > .menu_wrap {
  margin-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: transparent;
  position: relative;
}

.Tos > .title > .menu_wrap > .menuBtn {
  padding: 1px 22px 0px 20px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  background-color: #d8d8d85d;
  margin-right: 10px;
  border-radius: 5px;
  color: #3c3c3c;
}
.Tos > .title > .menu_wrap > .menuBtn:hover {
  cursor: pointer;
}
.Tos > .title > .menu_wrap > .menuBtn:active {
  cursor: pointer;
}
.Tos > .title > .menu_wrap > .menuBtn > img {
  width: 22px;
  margin-bottom: 2px;
  margin-right: 3px;
}

/* edit_minus */
.Tos > .title .edit_minus {
  position: absolute;
  right: 0px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  color: #3c3c3c;
}
.Tos > .title .edit_minus img {
  width: 22px;
  margin-left: 10px;
}
.Tos > .title .edit_minus img:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.Tos > .title .edit_minus .edit_gray {
  display: none;
}
.Tos > .title .edit_minus .trash {
  display: none;
}
.Tos > .title .edit_minus .cancel_gay {
  display: none;
}
.Tos > .title .edit_minus .check_gay {
  display: none;
}

/* Tos_board */
.Tos > .Tos_board {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  overflow: hidden;
}
.Tos > .Tos_board > #Tos_board_all {
  height: 100%;
  padding-right: 15px;
  overflow-y: scroll;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  flex-direction: column;
  background-color: inherit;
  display: none;
}

/* scrollbar */
.Tos > .Tos_board > #Tos_board_all::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}
.Tos > .Tos_board > #Tos_board_all::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Tos > .Tos_board > #Tos_board_all::-webkit-scrollbar-track {
  background: transparent;
}
.Tos > .Tos_board > #Tos_board_all::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Tos > .Tos_board > #Tos_board_all::-webkit-scrollbar-button:end {
  background-color: transparent;
}

.Tos > .Tos_board > .Tos_board_tos {
  display: none;
}
.Tos > .Tos_board > .Tos_board_personal_information {
  display: none;
}
.Tos > .Tos_board > .Tos_board_sensitive_information {
  display: none;
}

/* Tos_edit */
.Tos > .Tos_board > .Tos_edit {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  line-height: 1.8;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  flex-direction: column;
  background-color: inherit;
  display: none;
}

/* scrollbar */
.Tos > .Tos_board > .Tos_edit::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}
.Tos > .Tos_board > .Tos_edit::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Tos > .Tos_board > .Tos_edit::-webkit-scrollbar-track {
  background: transparent;
}
.Tos > .Tos_board > .Tos_edit::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Tos > .Tos_board > .Tos_edit::-webkit-scrollbar-button:end {
  background-color: transparent;
}
