@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.Service_log_2 {
  width: 100%;
  height: 55px;
  font-family: "Pretendard-Regular";
  font-size: 17px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  color: #333;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 30px;
}
.Service_log_2 span:first-child {
  width: 12%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Service_log_2 span:nth-child(2) {
  width: 28%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Service_log_2 span:nth-child(3) {
  width: 48%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}
.Service_log_2 span:nth-child(4) {
  width: 12%;
  height: 42px;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 2px;
  overflow-x: auto;
}

/* .Service_log_2 span > scroll */
.Service_log_2 span::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}
.Service_log_2 span::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Service_log_2 span::-webkit-scrollbar-track {
  background: transparent;
}
.Service_log_2 span::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Service_log_2 span::-webkit-scrollbar-button:end {
  background-color: transparent;
}

@media screen and (max-width: 821px) and (min-width: 250px) {
  .Service_log_2 {
    width: 100%;
    height: 70px;
    font-family: "Pretendard-Regular";
    font-size: 0.9em;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    color: #333;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Service_log_2 span {
    width: 31%;
    height: 60px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
    word-break: break-all;
  }
  .Service_log_2 span:nth-child(2) {
    margin: 0px 2%;
  }
}
