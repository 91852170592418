@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

.Diagnosis {
  width: 100%;
  height: 100%;
  padding-right: 2px;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  overflow: hidden;
}
.Diagnosis > .title {
  width: 100%;
  height: 5%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  margin-bottom: 15px;
  color: #414141;
  letter-spacing: 1px;
  font-size: 22px;
  position: relative;
}
.Diagnosis > .title > .down {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}
.Diagnosis > .title > .down:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.Diagnosis > .title > .down > img {
  width: 18px;
  margin-right: 5px;
}
.Diagnosis > .title > .down > span {
  font-size: 15px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  font-size: 15px;
  margin-top: 2px;
  color: #368552;
}
.Diagnosis > .title > span {
  margin-left: 5px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
/* serchBar */
.Diagnosis > .title > .serchBar {
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  position: absolute;
  right: 8px;
  display: flex;
  flex-direction: row;
}
.Diagnosis > .title > .serchBar > #selectSearch {
  font-size: 16px;
  font-family: "Pretendard-Regular";
  width: 150px;
  height: 30px;
  margin-right: -10px;
  border: none;
  border-bottom: solid 1px rgba(188, 188, 188, 0.29);
  color: rgb(125, 125, 125);
  background-color: #f9f9f9;
  border-radius: 5px;
}
.Diagnosis > .title > .serchBar > .inputSearch {
  font-size: 16px;
  font-family: "Pretendard-Regular";
  margin-right: 10px;
  width: 250px;
  height: 30px;
  padding: 0px 5px;
  box-sizing: border-box;
  border: none;
  border-bottom: solid 1px rgba(188, 188, 188, 0.29);
  background-color: #f9f9f9;
  border-radius: 5px;
}
.Diagnosis > .title > .serchBar > .querySearch {
  height: 25px;
  margin-right: 10px;
}
.Diagnosis > .title > .serchBar > .querySearch:hover {
  cursor: pointer;
}

/* Diagnosis_board */
.Diagnosis > .Diagnosis_board {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  background-color: #f9f9f9;
}

/* Diagnosis_detail */
.Diagnosis > .title > .double_left {
  width: 27px;
  margin-right: 10px;
}
.Diagnosis > .title > .double_left:hover {
  cursor: pointer;
}
.Diagnosis > .title > .title_text > span:first-child {
  margin-right: 30px;
}
.Diagnosis > .title > .title_date {
  position: absolute;
  right: 40px;
  bottom: 0;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: #414141;
}
.Diagnosis > .title > .del {
  position: absolute;
  right: 12px;
  bottom: 0;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  color: #414141;
}
.Diagnosis > .title > .del:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.Diagnosis > .Diagnosis_detail {
  width: 100%;
  height: 94%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  overflow-y: scroll;
  overflow-x: hidden; /* 추가된 부분 */
}

/* user_wrap_title */
.Diagnosis > .Diagnosis_detail > .user_wrap_title {
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: #368552;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* user_wrap */
.Diagnosis > .Diagnosis_detail > .user_wrap {
  width: 100%;
  background-color: inherit;
  height: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Diagnosis > .Diagnosis_detail > .user_wrap > .diagnosisImg {
  width: 30%;
  background-color: inherit;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
/* .Diagnosis > .Diagnosis_detail > .user_wrap > .diagnosisImg > img */

/* userInformation */
.Diagnosis > .Diagnosis_detail > .user_wrap > .userInformation {
  width: 70%;
  background-color: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.Diagnosis > .Diagnosis_detail > .user_wrap > .userInformation div {
  width: 100%;
  background-color: inherit;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  padding: 20px;
  margin-left: 20px;
}
.Diagnosis > .Diagnosis_detail > .user_wrap > .userInformation div span {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  border-bottom: solid 1px #adadad3d;
}
.Diagnosis
  > .Diagnosis_detail
  > .user_wrap
  > .userInformation
  div:nth-child(5)
  span {
  width: 100%;
}
.Diagnosis
  > .Diagnosis_detail
  > .user_wrap
  > .userInformation
  div
  span:first-child {
  margin-right: 5%;
}

/* diagnosis_wrap_title */
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap_title {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  min-height: 50px;
  background-color: #368552;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  border-radius: 5px;
}

/* diagnosis_wrap */
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap {
  margin: 10px 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}

/* diagnosis_wrap_top */
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap > .diagnosis_wrap_top {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: inherit;
  position: relative;
  /* margin-bottom: 10px; */
  margin: 10px 0px 20px 0px;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap > .diagnosis_wrap_top > div {
  padding: 12px 22px;
  background-color: #d8d8d85d;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  border-radius: 5px;
  margin-right: 10px;
  letter-spacing: 2px;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  > .diagnosis_wrap_top
  > div:first-child {
  position: absolute;
  right: 10px;
}

/* diagnosis_box */
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .order_box {
  width: 100%;
  height: 40px;
  min-height: 40px;
  background-color: inherit;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .order_box > div:first-child {
  width: 120px;
  height: 100%;
  background-color: #d8d8d85d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .order_box > div:nth-child(2) {
  width: 170px;
  height: 100%;
  background-color: inherit;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 0px 10px;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .order_box
  > div:nth-child(2)
  > div {
  width: 96%;
  height: 100%;
  background-color: #d8d8d85d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 10px;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .order_box > div:nth-child(3) {
  width: 900px;
  height: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .order_box
  > div:nth-child(3)
  > div {
  width: 130px;
  height: 100%;
  background-color: #d8d8d85d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 10px;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .order_box > .result {
  width: 130px;
  height: 100%;
  background-color: #d8d8d85d;
  border-radius: 5px;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .diagnosis_box {
  width: 100%;
  height: 140px;
  min-height: 140px;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .diagnosis_box > .name {
  width: 120px;
  height: 100%;
  background-color: inherit;
  font-size: 16px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .diagnosis_box > .result {
  width: 170px;
  height: 100%;
  background-color: inherit;
  font-size: 17px;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 30px 0px 10px;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .diagnosis_box
  > .result
  > .color_name {
  width: 96%;
  height: 30px;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d85d;
  border-radius: 5px;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .diagnosis_box
  > .result
  > .color_box {
  width: 96%;
  height: 85px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .diagnosis_box > .standard {
  width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .diagnosis_box
  > .standard
  > .standard_color {
  width: 900px;
  height: 85px;
  margin-bottom: 5px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .diagnosis_box
  > .standard
  > .standard_color
  > div {
  width: 130px;
  height: 100%;
  background-color: rgb(250, 237, 237);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 10px;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .diagnosis_box
  > .standard
  > .similarity {
  width: 900px;
  height: 30px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Diagnosis
  > .Diagnosis_detail
  > .diagnosis_wrap
  .diagnosis_box
  > .standard
  > .similarity
  > div {
  width: 130px;
  height: 30px;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #d8d8d85d;
  margin: 0 10px;
}
.Diagnosis > .Diagnosis_detail > .diagnosis_wrap .diagnosis_box > .result2 {
  width: 130px;
  height: 100%;
  background-color: inherit;
  font-size: 16px;
  font-family: "Pretendard-Regular";
  font-weight: bold;
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

/* scrollbar */
.Diagnosis > .Diagnosis_detail::-webkit-scrollbar {
  width: 12px;
  height: 5px;
}
.Diagnosis > .Diagnosis_detail::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Diagnosis > .Diagnosis_detail::-webkit-scrollbar-track {
  background: transparent;
}
.Diagnosis > .Diagnosis_detail::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Diagnosis > .Diagnosis_detail::-webkit-scrollbar-button:end {
  background-color: transparent;
}
.Diagnosis > .Diagnosis_detail > .user_wrap::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.Diagnosis > .Diagnosis_detail > .user_wrap::-webkit-scrollbar-thumb {
  height: 20%;
  background: #d3d3d3d0;
  border-radius: 5px;
}
.Diagnosis > .Diagnosis_detail > .user_wrap::-webkit-scrollbar-track {
  background: transparent;
}
.Diagnosis > .Diagnosis_detail > .user_wrap::-webkit-scrollbar-button:start {
  background-color: transparent;
}
.Diagnosis > .Diagnosis_detail > .user_wrap::-webkit-scrollbar-button:end {
  background-color: transparent;
}