@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
.Diagnosis_1 {
  width: 100%;
  height: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.Diagnosis_1 > .boxTitle {
  width: 100%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "Pretendard-Regular";
  font-size: 16px;
  font-weight: normal;
  color: rgb(255, 255, 255);
  background-color: #368552;
  border-radius: 5px;
  padding: 0 30px;
}
.Diagnosis_1 > .boxTitle span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Diagnosis_1 > .boxTitle span:first-child {
  width: 3%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(2) {
  /* className='idxDiv' */
}
.Diagnosis_1 > .boxTitle span:nth-child(3) {
  width: 8%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(4) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(5) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(6) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(7) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(8) {
  width: 8%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(9) {
  width: 8%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(10) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(11) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(12) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(13) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(14) {
  width: 7%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .boxTitle span:nth-child(15) {
  width: 12%;
  background-color: transparent;
  text-align: center;
}
.Diagnosis_1 > .diagnosisList_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
}