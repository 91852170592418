@charset "utf-8";

@font-face {
  font-family: "Pretendard-Regular";
  src: url(./font/Pretendard-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url(./font/SourceSansPro-SemiBold.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
}

.Admin_page {
  width: 98vw;
  max-width: 1920px;
  height: 96vh;
  max-height: 1080px;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  margin: 15px;
  border: solid 1px rgba(0, 0, 0, 0.056);
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.249);
  overflow: hidden;
}
.Admin_page > .seen_board {
  padding: 0 12px;
  padding-left: 24px;
  padding-bottom: 20px;
  max-width: 91.5%;
  width: auto;
  min-width: 91.5%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
/* day */
.Admin_page > .seen_board > .day {
  width: 100%;
  height: 5%;
  min-height: 30px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "SourceSansPro-SemiBold";
  font-weight: bold;
  font-style: normal;
  font-size: 17px;
  color: #414141;
  position: relative;
}
.day > #Dwrap {
  width: 150px;
}
.day > .today_DATE {
  position: absolute;
  right: 10px;
}
